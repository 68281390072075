<template>
  <div class="app">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>系统</el-breadcrumb-item>
          <el-breadcrumb-item>广告管理</el-breadcrumb-item>
          <el-breadcrumb-item>推广商家</el-breadcrumb-item>
          <el-breadcrumb-item>编辑</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-form ref="form" :model="form" label-width="80px">
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">基本信息</span>
            <el-divider></el-divider>
          </el-col>

          <el-col :span="24" class="hight">
            <el-form-item
              label="选择商家"
              prop="userName"
              :rules="[
                { required: true, message: '请选择商家', trigger: 'blur' },
              ]"
            >
              <el-input
                placeholder=""
                v-model="form.userName"
                :disabled="disabled"
                @focus="dialogCancelVisible = true"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="选择门店"
              prop="shopName"
              :rules="[
                { required: true, message: '请选择门店', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="form.shopName"
                placeholder="请选择"
                :disabled="disabled"
                clearable
              >
                <el-option label="全部"></el-option>
                <!--<el-option v-for="(rs,index) in enable" :label="rs" :value="index"
                                           :key="index"></el-option>-->
              </el-select>
            </el-form-item>
            <el-form-item
              label="所属区域"
              prop="area"
              :rules="[
                { required: true, message: '请填写所属区域', trigger: 'blur' },
              ]"
            >
              <el-input
                placeholder=""
                v-model="form.area"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="时间"
              prop="date"
              :rules="[
                { required: true, message: '请选择起止时间', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                v-model="date"
                @change="orderDate" @input="immediUpdate"
                type="datetimerange"
                range-separator="~"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                                start-placeholder="起始时间"
                end-placeholder="截止时间"
                align="right"
              >
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="排序"
              prop="sort"
              :rules="[
                { required: true, message: '请填写排序', trigger: 'blur' },
              ]"
            >
              <el-input-number
                size="mini"
                placeholder="请输入排序"
                v-model="form.sort"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="描述"
              prop="remark"
              :rules="[
                { required: true, message: '请输入详细地址', trigger: 'blur' },
                {
                  min: 6,
                  max: 200,
                  message: '长度在 6 到 200 个字符',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                type="textarea"
                placeholder=""
                v-model="form.remark"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="isShow">
              <el-switch
                v-model="form.isShow"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">投放范围</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="24" class="hight">
            <el-form-item
              label="选择范围"
              prop="fanwei"
              :rules="[
                { required: true, message: '请选择范围', trigger: 'blur' },
              ]"
            >
              <el-input
                placeholder=""
                v-model="form.fanwei"
                @focus="dialogTransferVisible = true"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-button icon="el-icon-check" type="primary" @click="back">
          取消
        </el-button>
        <el-button icon="el-icon-back" type="primary" @click="success('form')">
          提交
        </el-button>
      </div>
    </el-form>
    <shop @dialog="getDialog" :row="row" v-if="dialogCancelVisible"></shop>
    <transfer
      @dialog="getDialog"
      :row="row"
      v-if="dialogTransferVisible"
    ></transfer>
  </div>
</template>
<script>
import shop from "@/components/admin/adv/mp/shop.vue";
import transfer from "@/components/admin/adv/mp/transfer.vue";

export default {
  components: {
    shop,
    transfer,
  },
  props: {},
  data() {
    return {
      span: 6,
      date: "",
      disabled: false,
      row: "",
      dialogCancelVisible: false,
      dialogTransferVisible: false,
      form: {
        startTime: "",
        endTime: "",
        userName: "",
      },
    };
  },
  methods: {
    async infoData() {
      this.showPic = true;
      let id = this.$route.query.id;
      if (id) {
        await this.$get(this.$api.operationCenterDetail, { id }).then((res) => {
          if (res.code == 1) {
            this.form = res.data;
            this.getUserinfo();
            if (this.form.enable) {
              this.form.enable = "true";
            } else {
              this.form.enable = "false";
            }
            this.disabled = false;
            this.isEdit = false;
          }
        });
      }
    },
    back() {
      let id = this.$route.query.id;
      if (this.isEmpty(id)) {
        this.$router.go(-1); //返回上一层
      } else {
        this.isEdit = true;
        this.disabled = true;
      }
    },
    success(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const url = this.$route.query.id
                ? this.$api.operationCenterUpdate
                : this.$api.operationCenterAdd;
              // this.form.userName = this.form.realName;
              this.post(url, this.form);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          this.$message({
            type: "warning",
            message: "请填写完整信息！",
          });
          return false;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          location.reload();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    orderDate(obj) {
      if (obj) {
        this.where.startTime = obj[0];
        this.where.endTime = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startTime = '';
          this.where.endTime = '';
        }
      });
    },
    getDialog(val) {
      this.dialogCancelVisible = val;
      this.dialogTransferVisible = val;
    },
  },
  created() {
    this.infoData();
  },
};
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.hight {
  width: 460px;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 24px;
}
/deep/ .el-input,
.el-select {
  width: 100%;
}
/deep/ .el-range-editor--small.el-input__inner {
  width: 340px;
}
</style>

