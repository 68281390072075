var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"admin_main_block"},[_c('div',{staticClass:"admin_breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/Admin/index' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("系统")]),_c('el-breadcrumb-item',[_vm._v("广告管理")]),_c('el-breadcrumb-item',[_vm._v("推广商家")]),_c('el-breadcrumb-item',[_vm._v("编辑")])],1)],1)]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',{attrs:{"gutter":40}},[_c('el-col',{attrs:{"span":24}},[_c('span',{staticClass:"grid-divider"},[_vm._v("基本信息")]),_c('el-divider')],1),_c('el-col',{staticClass:"hight",attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"选择商家","prop":"userName","rules":[
              { required: true, message: '请选择商家', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":_vm.disabled},on:{"focus":function($event){_vm.dialogCancelVisible = true}},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}})],1),_c('el-form-item',{attrs:{"label":"选择门店","prop":"shopName","rules":[
              { required: true, message: '请选择门店', trigger: 'blur' } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":_vm.disabled,"clearable":""},model:{value:(_vm.form.shopName),callback:function ($$v) {_vm.$set(_vm.form, "shopName", $$v)},expression:"form.shopName"}},[_c('el-option',{attrs:{"label":"全部"}})],1)],1),_c('el-form-item',{attrs:{"label":"所属区域","prop":"area","rules":[
              { required: true, message: '请填写所属区域', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":true},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}})],1),_c('el-form-item',{attrs:{"label":"时间","prop":"date","rules":[
              { required: true, message: '请选择起止时间', trigger: 'blur' } ]}},[_c('el-date-picker',{attrs:{"type":"datetimerange","range-separator":"~","value-format":"yyyy-MM-dd HH:mm:ss","format":"yyyy-MM-dd HH:mm:ss","start-placeholder":"起始时间","end-placeholder":"截止时间","align":"right"},on:{"change":_vm.orderDate,"input":_vm.immediUpdate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('i',{staticClass:"el-icon-date"})])],1),_c('el-form-item',{attrs:{"label":"排序","prop":"sort","rules":[
              { required: true, message: '请填写排序', trigger: 'blur' } ]}},[_c('el-input-number',{attrs:{"size":"mini","placeholder":"请输入排序"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})],1),_c('el-form-item',{attrs:{"label":"描述","prop":"remark","rules":[
              { required: true, message: '请输入详细地址', trigger: 'blur' },
              {
                min: 6,
                max: 200,
                message: '长度在 6 到 200 个字符',
                trigger: 'blur',
              } ]}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"","disabled":_vm.disabled},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1),_c('el-form-item',{attrs:{"label":"状态","prop":"isShow"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":1,"inactive-value":0},model:{value:(_vm.form.isShow),callback:function ($$v) {_vm.$set(_vm.form, "isShow", $$v)},expression:"form.isShow"}})],1)],1)],1)],1),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-row',{attrs:{"gutter":40}},[_c('el-col',{attrs:{"span":24}},[_c('span',{staticClass:"grid-divider"},[_vm._v("投放范围")]),_c('el-divider')],1),_c('el-col',{staticClass:"hight",attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"选择范围","prop":"fanwei","rules":[
              { required: true, message: '请选择范围', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"placeholder":"","disabled":_vm.disabled},on:{"focus":function($event){_vm.dialogTransferVisible = true}},model:{value:(_vm.form.fanwei),callback:function ($$v) {_vm.$set(_vm.form, "fanwei", $$v)},expression:"form.fanwei"}})],1)],1)],1)],1),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary"},on:{"click":_vm.back}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"icon":"el-icon-back","type":"primary"},on:{"click":function($event){return _vm.success('form')}}},[_vm._v(" 提交 ")])],1)]),(_vm.dialogCancelVisible)?_c('shop',{attrs:{"row":_vm.row},on:{"dialog":_vm.getDialog}}):_vm._e(),(_vm.dialogTransferVisible)?_c('transfer',{attrs:{"row":_vm.row},on:{"dialog":_vm.getDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }